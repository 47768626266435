export type Country = {
  isoCode: string;
  label: string;
};

export const DEFAULT_COUNTRY: Country = { isoCode: "COL", label: "Colombia" };

export const COUNTRIES: Country[] = [
  { isoCode: "AFG", label: "Afghanistan" },
  { isoCode: "ALB", label: "Albania" },
  { isoCode: "DZA", label: "Algeria" },
  { isoCode: "AND", label: "Andorra" },
  { isoCode: "AGO", label: "Angola" },
  { isoCode: "AIA", label: "Anguilla" },
  { isoCode: "ATG", label: "Antigua and Barbuda" },
  { isoCode: "ARG", label: "Argentina" },
  { isoCode: "ARM", label: "Armenia" },
  { isoCode: "ABW", label: "Aruba" },
  { isoCode: "AUS", label: "Australia" },
  { isoCode: "AUT", label: "Austria" },
  { isoCode: "AZE", label: "Azerbaijan" },
  { isoCode: "BHS", label: "Bahamas" },
  { isoCode: "BHR", label: "Bahrain" },
  { isoCode: "BGD", label: "Bangladesh" },
  { isoCode: "BRB", label: "Barbados" },
  { isoCode: "BLR", label: "Belarus" },
  { isoCode: "BEL", label: "Belgium" },
  { isoCode: "BLZ", label: "Belize" },
  { isoCode: "BEN", label: "Benin" },
  { isoCode: "BMU", label: "Bermuda" },
  { isoCode: "BTN", label: "Bhutan" },
  { isoCode: "BOL", label: "Bolivia" },
  { isoCode: "BES", label: "Bonaire Sint Eustatius and Saba" },
  { isoCode: "BIH", label: "Bosnia and Herzegovina" },
  { isoCode: "BWA", label: "Botswana" },
  { isoCode: "BRA", label: "Brazil" },
  { isoCode: "VGB", label: "British Virgin Islands" },
  { isoCode: "BRN", label: "Brunei" },
  { isoCode: "BGR", label: "Bulgaria" },
  { isoCode: "BFA", label: "Burkina Faso" },
  { isoCode: "BDI", label: "Burundi" },
  { isoCode: "KHM", label: "Cambodia" },
  { isoCode: "CMR", label: "Cameroon" },
  { isoCode: "CAN", label: "Canada" },
  { isoCode: "CPV", label: "Cape Verde" },
  { isoCode: "CYM", label: "Cayman Islands" },
  { isoCode: "CAF", label: "Central African Republic" },
  { isoCode: "TCD", label: "Chad" },
  { isoCode: "CHL", label: "Chile" },
  { isoCode: "CHN", label: "China" },
  { isoCode: "COL", label: "Colombia" },
  { isoCode: "COM", label: "Comoros" },
  { isoCode: "COG", label: "Congo" },
  { isoCode: "COK", label: "Cook Islands" },
  { isoCode: "CRI", label: "Costa Rica" },
  { isoCode: "CIV", label: "Cote d'Ivoire" },
  { isoCode: "HRV", label: "Croatia" },
  { isoCode: "CUB", label: "Cuba" },
  { isoCode: "CUW", label: "Curacao" },
  { isoCode: "CYP", label: "Cyprus" },
  { isoCode: "CZE", label: "Czechia" },
  { isoCode: "COD", label: "Democratic Republic of Congo" },
  { isoCode: "DNK", label: "Denmark" },
  { isoCode: "DJI", label: "Djibouti" },
  { isoCode: "DMA", label: "Dominica" },
  { isoCode: "DOM", label: "Dominican Republic" },
  { isoCode: "ECU", label: "Ecuador" },
  { isoCode: "EGY", label: "Egypt" },
  { isoCode: "SLV", label: "El Salvador" },
  { isoCode: "GNQ", label: "Equatorial Guinea" },
  { isoCode: "ERI", label: "Eritrea" },
  { isoCode: "EST", label: "Estonia" },
  { isoCode: "SWZ", label: "Eswatini" },
  { isoCode: "ETH", label: "Ethiopia" },
  { isoCode: "FRO", label: "Faeroe Islands" },
  { isoCode: "FLK", label: "Falkland Islands" },
  { isoCode: "FJI", label: "Fiji" },
  { isoCode: "FIN", label: "Finland" },
  { isoCode: "FRA", label: "France" },
  { isoCode: "PYF", label: "French Polynesia" },
  { isoCode: "GAB", label: "Gabon" },
  { isoCode: "GMB", label: "Gambia" },
  { isoCode: "GEO", label: "Georgia" },
  { isoCode: "DEU", label: "Germany" },
  { isoCode: "GHA", label: "Ghana" },
  { isoCode: "GIB", label: "Gibraltar" },
  { isoCode: "GRC", label: "Greece" },
  { isoCode: "GRL", label: "Greenland" },
  { isoCode: "GRD", label: "Grenada" },
  { isoCode: "GUM", label: "Guam" },
  { isoCode: "GTM", label: "Guatemala" },
  { isoCode: "GGY", label: "Guernsey" },
  { isoCode: "GIN", label: "Guinea" },
  { isoCode: "GNB", label: "Guinea-Bissau" },
  { isoCode: "GUY", label: "Guyana" },
  { isoCode: "HTI", label: "Haiti" },
  { isoCode: "HND", label: "Honduras" },
  { isoCode: "HKG", label: "Hong Kong" },
  { isoCode: "HUN", label: "Hungary" },
  { isoCode: "ISL", label: "Iceland" },
  { isoCode: "IND", label: "India" },
  { isoCode: "IDN", label: "Indonesia" },
  { isoCode: "IRN", label: "Iran" },
  { isoCode: "IRQ", label: "Iraq" },
  { isoCode: "IRL", label: "Ireland" },
  { isoCode: "IMN", label: "Isle of Man" },
  { isoCode: "ISR", label: "Israel" },
  { isoCode: "ITA", label: "Italy" },
  { isoCode: "JAM", label: "Jamaica" },
  { isoCode: "JPN", label: "Japan" },
  { isoCode: "JEY", label: "Jersey" },
  { isoCode: "JOR", label: "Jordan" },
  { isoCode: "KAZ", label: "Kazakhstan" },
  { isoCode: "KEN", label: "Kenya" },
  { isoCode: "KIR", label: "Kiribati" },
  { isoCode: "KWT", label: "Kuwait" },
  { isoCode: "KGZ", label: "Kyrgyzstan" },
  { isoCode: "LAO", label: "Laos" },
  { isoCode: "LVA", label: "Latvia" },
  { isoCode: "LBN", label: "Lebanon" },
  { isoCode: "LSO", label: "Lesotho" },
  { isoCode: "LBR", label: "Liberia" },
  { isoCode: "LBY", label: "Libya" },
  { isoCode: "LIE", label: "Liechtenstein" },
  { isoCode: "LTU", label: "Lithuania" },
  { isoCode: "LUX", label: "Luxembourg" },
  { isoCode: "MAC", label: "Macao" },
  { isoCode: "MDG", label: "Madagascar" },
  { isoCode: "MWI", label: "Malawi" },
  { isoCode: "MYS", label: "Malaysia" },
  { isoCode: "MDV", label: "Maldives" },
  { isoCode: "MLI", label: "Mali" },
  { isoCode: "MLT", label: "Malta" },
  { isoCode: "MHL", label: "Marshall Islands" },
  { isoCode: "MRT", label: "Mauritania" },
  { isoCode: "MUS", label: "Mauritius" },
  { isoCode: "MEX", label: "Mexico" },
  { isoCode: "FSM", label: "Micronesia (country)" },
  { isoCode: "MDA", label: "Moldova" },
  { isoCode: "MCO", label: "Monaco" },
  { isoCode: "MNG", label: "Mongolia" },
  { isoCode: "MNE", label: "Montenegro" },
  { isoCode: "MSR", label: "Montserrat" },
  { isoCode: "MAR", label: "Morocco" },
  { isoCode: "MOZ", label: "Mozambique" },
  { isoCode: "MMR", label: "Myanmar" },
  { isoCode: "NAM", label: "Namibia" },
  { isoCode: "NRU", label: "Nauru" },
  { isoCode: "NPL", label: "Nepal" },
  { isoCode: "NLD", label: "Netherlands" },
  { isoCode: "NCL", label: "New Caledonia" },
  { isoCode: "NZL", label: "New Zealand" },
  { isoCode: "NIC", label: "Nicaragua" },
  { isoCode: "NER", label: "Niger" },
  { isoCode: "NGA", label: "Nigeria" },
  { isoCode: "NIU", label: "Niue" },
  { isoCode: "PRK", label: "North Korea" },
  { isoCode: "MKD", label: "North Macedonia" },
  { isoCode: "MNP", label: "Northern Mariana Islands" },
  { isoCode: "NOR", label: "Norway" },
  { isoCode: "OMN", label: "Oman" },
  { isoCode: "PAK", label: "Pakistan" },
  { isoCode: "PLW", label: "Palau" },
  { isoCode: "PSE", label: "Palestine" },
  { isoCode: "PAN", label: "Panama" },
  { isoCode: "PNG", label: "Papua New Guinea" },
  { isoCode: "PRY", label: "Paraguay" },
  { isoCode: "PER", label: "Peru" },
  { isoCode: "PHL", label: "Philippines" },
  { isoCode: "PCN", label: "Pitcairn" },
  { isoCode: "POL", label: "Poland" },
  { isoCode: "PRT", label: "Portugal" },
  { isoCode: "PRI", label: "Puerto Rico" },
  { isoCode: "QAT", label: "Qatar" },
  { isoCode: "ROU", label: "Romania" },
  { isoCode: "RUS", label: "Russia" },
  { isoCode: "RWA", label: "Rwanda" },
  { isoCode: "SHN", label: "Saint Helena" },
  { isoCode: "KNA", label: "Saint Kitts and Nevis" },
  { isoCode: "LCA", label: "Saint Lucia" },
  { isoCode: "SPM", label: "Saint Pierre and Miquelon" },
  { isoCode: "VCT", label: "Saint Vincent and the Grenadines" },
  { isoCode: "WSM", label: "Samoa" },
  { isoCode: "SMR", label: "San Marino" },
  { isoCode: "STP", label: "Sao Tome and Principe" },
  { isoCode: "SAU", label: "Saudi Arabia" },
  { isoCode: "SEN", label: "Senegal" },
  { isoCode: "SRB", label: "Serbia" },
  { isoCode: "SYC", label: "Seychelles" },
  { isoCode: "SLE", label: "Sierra Leone" },
  { isoCode: "SGP", label: "Singapore" },
  { isoCode: "SXM", label: "Sint Maarten (Dutch part)" },
  { isoCode: "SVK", label: "Slovakia" },
  { isoCode: "SVN", label: "Slovenia" },
  { isoCode: "SLB", label: "Solomon Islands" },
  { isoCode: "SOM", label: "Somalia" },
  { isoCode: "ZAF", label: "South Africa" },
  { isoCode: "KOR", label: "South Korea" },
  { isoCode: "SSD", label: "South Sudan" },
  { isoCode: "ESP", label: "Spain" },
  { isoCode: "LKA", label: "Sri Lanka" },
  { isoCode: "SDN", label: "Sudan" },
  { isoCode: "SUR", label: "Suriname" },
  { isoCode: "SWE", label: "Sweden" },
  { isoCode: "CHE", label: "Switzerland" },
  { isoCode: "SYR", label: "Syria" },
  { isoCode: "TWN", label: "Taiwan" },
  { isoCode: "TJK", label: "Tajikistan" },
  { isoCode: "TZA", label: "Tanzania" },
  { isoCode: "THA", label: "Thailand" },
  { isoCode: "TLS", label: "Timor" },
  { isoCode: "TGO", label: "Togo" },
  { isoCode: "TKL", label: "Tokelau" },
  { isoCode: "TON", label: "Tonga" },
  { isoCode: "TTO", label: "Trinidad and Tobago" },
  { isoCode: "TUN", label: "Tunisia" },
  { isoCode: "TUR", label: "Turkey" },
  { isoCode: "TKM", label: "Turkmenistan" },
  { isoCode: "TCA", label: "Turks and Caicos Islands" },
  { isoCode: "TUV", label: "Tuvalu" },
  { isoCode: "UGA", label: "Uganda" },
  { isoCode: "UKR", label: "Ukraine" },
  { isoCode: "ARE", label: "United Arab Emirates" },
  { isoCode: "GBR", label: "United Kingdom" },
  { isoCode: "USA", label: "United States" },
  { isoCode: "VIR", label: "United States Virgin Islands" },
  { isoCode: "URY", label: "Uruguay" },
  { isoCode: "UZB", label: "Uzbekistan" },
  { isoCode: "VUT", label: "Vanuatu" },
  { isoCode: "VAT", label: "Vatican" },
  { isoCode: "VEN", label: "Venezuela" },
  { isoCode: "VNM", label: "Vietnam" },
  { isoCode: "WLF", label: "Wallis and Futuna" },
  { isoCode: "ESH", label: "Western Sahara" },
  { isoCode: "YEM", label: "Yemen" },
  { isoCode: "ZMB", label: "Zambia" },
  { isoCode: "ZWE", label: "Zimbabwe" },
];

export function getCountryNameByIsoCode(isoCode: string) {
  const country = COUNTRIES.find((c) => c.isoCode === isoCode);
  return country ? country.label : isoCode;
}
