import { MenuItemPair } from "../../components/Select";

export const COVID_YEARS: MenuItemPair[] = [
  {
    text: "2020",
    value: "2020",
  },
  {
    text: "2021",
    value: "2021",
  },
];
